import 'lazysizes';
import { createApp } from 'vue';
import { createStore } from 'vuex';

// Plugins for zone-level customizations
import loadZoneCustomizations from '@loreal/zone-plugin-loader';
import init from '@loreal/sisyphus';

// Sitecore-Vue bridge
import SitecoreVueAdapter from '@Foundation/sitecore/SitecoreVueAdapter';

// Helpers
import { requireAll } from '@Foundation/utilities/helpers';

// Foundation
import Foundation from '@Foundation';
import LoadScriptModule from '@Foundation/loadscript';
import eventBus, { exposeGlobals } from '@loreal/eventbus-js';
import { tag } from '@Foundation/analyticsHandler/directives/tag';

// Feature
import AlertModule from '@Feature/Alert';
import CookieBanner from '@Feature/PageContentExt';
import FilterModule from '@Feature/Filter';
import GenericForm from '@Feature/GenericForm';
import HeaderLogo from '@Feature/HeaderLogo';
import Iframepopup from '@Feature/IframePopup';
import LiveChat from '@Feature/LiveChat';
import NavigationModule from '@Feature/Navigation';
import NewsletterModule from '@Feature/NewsLetter';
import OapAccountNavigation from '@Feature/OapAccountNavigation';
import OapBadge from '@Feature/OapBadge';
import OapBeautyGeniusWrapper from '@Feature/OapBeautyGeniusWrapper';
import OapCard from '@Feature/OapCard';
import OapCardPopin from '@Feature/OapCardPopin';
import OapCountdown from '@Feature/OapCountdown';
import OapEventfulTextInput from '@Feature/OapEventfulTextInput';
import OapGeolocationPopin from '@Feature/OapGeolocationPopin';
import OapIframe from '@Feature/OapIframe';
import OapLoader from '@Feature/OapLoader';
import OapPopin from '@Feature/OapPopin';
import OapPopup from '@Feature/OapPopup';
import OapPromoBar from '@Feature/OapPromoBar';
import OapRoutineBuilder from '@Feature/RoutineBuilder';
import OapScroller from '@Feature/OapScroller';
import OapSlider from '@Feature/OapSlider';
import OapStrikethroughScrolling from '@Feature/OapStrikethroughScrolling';
import OapTabComponent from '@Feature/OapTab';
import OneTrustCookieHelper from '@Feature/OneTrust';
import Overlay from '@Feature/Overlay';
import Search from '@Feature/Search';
import SearchSuggestions from '@Feature/SearchSuggestions';
import Sfmc from '@Feature/SFMC';
import Slider from '@Feature/Slider';
import VideoPlaceholder from '@Feature/VideoPlaceholder';
import OapPageNavigation from '@Feature/OapPageNavigation';
import VirtualTryOn from '@Feature/VirtualTryOn';
import AccessibilityStore from '@Foundation/accessibilityStore';
import OapPromoCard from '@Feature/OapPromoCard';
import OapCarouselControls from '@Feature/CarouselControls';
import VueModels from '@Feature/VueModels';

exposeGlobals();

const app = createApp({});
app.config.compilerOptions.whitespace = 'preserve';
app.config.performance = true;

// Register directives
Object.entries({
  ...Foundation.directives,
  ...LoadScriptModule.directives,
  ...NavigationModule.directives,
  ...Search.directives,
  tag,
}).forEach(([name, directive]) => app.directive(name, directive));

// Register components
Object.entries({
  ...AlertModule.components,
  ...CookieBanner.components,
  ...FilterModule.components,
  ...Foundation.components,
  ...GenericForm.components,
  ...HeaderLogo.components,
  ...Iframepopup.components,
  ...LiveChat.components,
  ...LoadScriptModule.components,
  ...NavigationModule.components,
  ...NewsletterModule.components,
  ...OapAccountNavigation.components,
  ...OapBadge.components,
  ...OapBeautyGeniusWrapper.components,
  ...OapCard.components,
  ...OapCardPopin.components,
  ...OapCountdown.components,
  ...OapEventfulTextInput.components,
  ...OapGeolocationPopin.components,
  ...OapIframe.components,
  ...OapLoader.components,
  ...OapPopin.components,
  ...OapPopup.components,
  ...OapPromoBar.components,
  ...OapRoutineBuilder.components,
  ...OapScroller.components,
  ...OapSlider.components,
  ...OapStrikethroughScrolling.components,
  ...OapTabComponent.components,
  ...OneTrustCookieHelper.components,
  ...Overlay.components,
  ...SearchSuggestions.components,
  ...Sfmc.components,
  ...Slider.components,
  ...VideoPlaceholder.components,
  ...OapPageNavigation.components,
  ...VirtualTryOn.components,
  ...OapPromoCard.components,
  ...OapCarouselControls.components,
  ...VueModels.components,
}).forEach(([name, component]) => app.component(name, component));

Object.entries({
  ...Foundation.filters,
}).forEach(([name, filter]) => app.filter(name, filter));

const store = createStore({
  plugins: [...VirtualTryOn.plugins],
  modules: {
    ...FilterModule.modules,
    ...VirtualTryOn.modules,
    ...AccessibilityStore.modules,
  },
});

app.use(store);

/**
 * Initialize any zone-level customizations.
 * Every zone/country can create their own Vue components and make them available
 * to their code through the use of a Vue.js Plugin
 *
 * @see https://vuejs.org/guide/reusability/plugins.html#writing-a-plugin
 * */
loadZoneCustomizations(app, store);
// Initialize third party loader
init();

requireAll(require.context('../../../Icons/', true, /\.svg$/));

document.addEventListener('lazybeforeunveil', (event) =>
  eventBus.emit('image.lazybeforeunveil', event)
);
window.RefAppStoreLocator && app.use(window.RefAppStoreLocator);
SitecoreVueAdapter.initializeVueApp(app.mount.bind(app, '#main-container'));
